import GlobalConfig from '@services/globalConfig'
import request from '@services/request.service'

export interface ILoginPayload {
  mobileNo?: string
}
export interface ISubmitOTPPayload {
  username: string
  otp: string | number
  userType: string
}
export interface IGoKwikAddressPayload {
  phone?: string
  go_kwik_token: string
  isKwikPass?: boolean
}
export const getLoginTypeApi = (data: ILoginPayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/send-otp`),
  data,
})

export const submitOTPApi = (data: ISubmitOTPPayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/submit-otp`),
  data,
})

export const getGoKwikAddressApi = (data: IGoKwikAddressPayload) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`api/customer/goKwikAddress`),
  data,
})

export class loginService {
  static async getLoginType(params: ILoginPayload) {
    const res = await request(getLoginTypeApi(params))
    return res
  }
  static async submitOTP(params: ISubmitOTPPayload) {
    const res = await request(submitOTPApi(params))
    return res
  }
  static async getGoKwikAddress(params: IGoKwikAddressPayload) {
    const res = await request(getGoKwikAddressApi(params))
    return res
  }
}
