import { Fragment, useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { Cross } from '@components/icons'
import { IDeviceInfo, useUI } from '@components/ui/context'
import { EmptyGuid, WELCOME10_TEXT } from '@components/utils/constants'
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import { Dialog, Transition } from '@headlessui/react'
import effectJSON from 'public/couponApplyAnim.json'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { getCurrentPage } from '@framework/utils/app-util'

interface IProps {
  show: boolean
  onClose: () => void
  deviceInfo: IDeviceInfo
  appVersionData: any
  user: any
}

const LoginPromptSuccessPopup = (props: IProps) => {
  const { show, onClose, deviceInfo, appVersionData, user } = props
  const { isOnlyMobile } = deviceInfo
  const [confettiVisibility, setConfettiVisibility] = useState(false)

  const { setAlert } = useUI()

  const copyHandler = () => {
    handleCopyCodeGAEvent()
    navigator.clipboard.writeText(WELCOME10_TEXT)
    setConfettiVisibility(true)
    setAlert({ type: 'success', msg: 'Code Copied!' })
    setTimeout(() => {
      onClose()
      setConfettiVisibility(false)
    }, 2000)
  }

  const handleCopyCodeGAEvent = () => {
    recordGA4Event(window, GA_EVENT.WELCOME_COPY_CODE, {
      promotion_name: WELCOME10_TEXT,
      promotion_promocode: WELCOME10_TEXT,
      device: isOnlyMobile ? 'Mobile' : 'Desktop',
      current_page: 'Copy Code Prompt',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  const defaultOptions = {
    loop: true,

    animationData: effectJSON,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const handleCopyCodePromptGAEvent = () => {
    recordGA4Event(window, GA_EVENT.COPY_CODE_PROMPT, {
      promotion_name: WELCOME10_TEXT,
      promotion_promocode: WELCOME10_TEXT,
    })
  }

  useEffect(() => {
    if (show) {
      handleCopyCodePromptGAEvent()
    }
  }, [show])

  const handleCloseGAEvent = () => {
    recordGA4Event(window, GA_EVENT.CLOSE_BUTTON, {
      current_page: 'Login Prompt',
      previous_page: getCurrentPage(),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  return (
    <>
      <Transition.Root
        show={show}
        as={Fragment}
        beforeLeave={() => (document.documentElement.style.overflow = 'auto')}
      >
        <Dialog as="div" className="relative z-99" onClose={() => onClose()}>
          <div className="fixed inset-0 left-0 bg-black/[.5]" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={`fixed ${
                  isOnlyMobile ? 'top-auto bottom-0' : 'inset-y-0'
                } right-0 flex max-w-full pointer-events-none`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-400"
                  enterFrom={`${
                    isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                  }`}
                  enterTo={`${
                    isOnlyMobile ? 'translate-y-0' : 'translate-x-0'
                  }`}
                  leave="transform transition ease-in-out duration-500 sm:duration-400"
                  leaveFrom={`${
                    isOnlyMobile ? 'translate-y-0' : 'translate-x-0'
                  }`}
                  leaveTo={`${
                    isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                  }`}
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div
                      className={`relative z-50 flex flex-col ${
                        isOnlyMobile ? 'h-auto' : 'h-full'
                      } bg-white shadow-xl`}
                    >
                      <button
                        type="button"
                        className="text-black rounded-md outline-none hover:text-gray-500 absolute cursor-pointer"
                        style={{
                          right: '20px',
                          top: '-40px',
                        }}
                        onClick={() => {
                          handleCloseGAEvent()
                          onClose()
                        }}
                        onMouseDown={() => {
                          onClose()
                        }}
                      >
                        <span className="sr-only">Close panel</span>
                        <Cross
                          className="relative top-0 w-7 h-7 stroke-white fill-white"
                          aria-hidden="true"
                          stroke="white"
                        />
                      </button>
                      {appVersionData?.loginbanner &&
                      appVersionData?.loginbanner?.length > 0 ? (
                        <>
                          {isOnlyMobile ? (
                            <>
                              {appVersionData?.loginbanner?.[0]
                                ?.loginbanner_mobileheroimage != '' ? (
                                <img
                                  src={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_mobileheroimage ||
                                    IMG_PLACEHOLDER
                                  }
                                  alt={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_title || ''
                                  }
                                  className={`image w-full h-40`}
                                />
                              ) : (
                                <img
                                  src={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_heroimage || IMG_PLACEHOLDER
                                  }
                                  alt={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_heroimage || ''
                                  }
                                  className={`image w-full h-40`}
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={
                                appVersionData?.loginbanner?.[0]
                                  ?.loginbanner_heroimage || IMG_PLACEHOLDER
                              }
                              alt={
                                appVersionData?.loginbanner?.[0]
                                  ?.loginbanner_heroimage || ''
                              }
                              className={`image w-full h-40`}
                            />
                          )}
                        </>
                      ) : null}
                      <div className="p-5">
                        <h1
                          className="text-base font-semibold"
                          style={{
                            color: 'rgba(0, 0, 0, 0.92)',
                          }}
                        >
                          10% OFF on your 1st Order!
                        </h1>
                        <p
                          className="font-light text-xs mt-2 leading-5"
                          style={{
                            color: 'rgba(0, 0, 0, 0.92)',
                            maxWidth: '80%',
                          }}
                        >
                          No limit on order amount! Apply at checkout. You'll
                          see this code during checkout as well.
                        </p>
                        <button
                          className="bg-black rounded text-white p-5 text-sm font-semibold mt-5 w-full flex justify-center items-center gap-x-2"
                          onClick={() => copyHandler()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M6.87504 2.08331C5.98671 2.08331 5.21688 2.59248 4.83647 3.33331H15.625C16.1996 3.33331 16.6667 3.8004 16.6667 4.37498V15.1636C17.4075 14.7836 17.9167 14.0133 17.9167 13.125V4.37498C17.9167 3.11165 16.8884 2.08331 15.625 2.08331H6.87504ZM4.37504 4.58331C3.1168 4.58331 2.08337 5.61674 2.08337 6.87498V15.625C2.08337 16.8832 3.1168 17.9166 4.37504 17.9166H13.125C14.3833 17.9166 15.4167 16.8832 15.4167 15.625V6.87498C15.4167 5.61674 14.3833 4.58331 13.125 4.58331H4.37504ZM4.37504 5.83331H13.125C13.7076 5.83331 14.1667 6.29239 14.1667 6.87498V15.625C14.1667 16.2076 13.7076 16.6666 13.125 16.6666H4.37504C3.79245 16.6666 3.33337 16.2076 3.33337 15.625V6.87498C3.33337 6.29239 3.79245 5.83331 4.37504 5.83331Z"
                              fill="white"
                            />
                          </svg>
                          <span>Copy Code: {WELCOME10_TEXT}</span>
                        </button>
                      </div>
                      {confettiVisibility ? (
                        <div className="w-full h-full absolute">
                          <Lottie
                            options={defaultOptions}
                            style={{
                              position: 'absolute',
                              left: '0',
                              right: '0',
                            }}
                            isPaused={false}
                            isClickToPauseDisabled
                            isStopped={false}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default LoginPromptSuccessPopup
