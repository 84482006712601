import { ArrowLeft, Cross, GoKwikIcon } from '@components/icons'
import { LoadingDots, useUI } from '@components/ui'
import { Dialog, Transition } from '@headlessui/react'
import { FormikProps } from 'formik'
import React, { Fragment, useRef } from 'react'
import {
  DEFAULT_LOGIN_VALUES,
  INewLoginFormProps,
  LOGIN_FORM_ID,
  LOGIN_FORM_SCHEMA,
  LOGIN_NEW_MODAL_FORM_FIELDS,
  generateLoginModalFooter,
  policyPopupEvent,
} from './loginHelper'
import SubmitButton from '@components/common/SubmitButton'
import {
  API_STATUS,
  EmptyGuid,
  LoginPageAction,
} from '@components/utils/constants'
import { IMG_PLACEHOLDER, INVALID_OTP } from '@components/utils/textVariables'
import Link from 'next/link'
import { IDeviceInfo } from '@components/ui/context'
import ErrorView from '@components/ui/ErrorView'
import {
  getCurrentPage,
  getErrorMessage,
  resetErrorSubmitData,
} from '@framework/utils/app-util'
import NewGoKwikLoginForm from './NewGoKwikLoginForm'
import Router from 'next/router'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { GA_EVENT } from 'hooks/ga_event.modal'

interface INewLoginModalDialogProps {
  setIsOTPSent: (val: boolean) => void
  setTimer: (val: number) => void
  deviceInfo: IDeviceInfo
  isOTPSent: boolean
  mobileNumber: string
  timer: number
  resendOTP: (val: any) => void
  getIfUserIsNewOrOld: (val: any) => void
  submitState: any
  validatedUserAPIStatus: API_STATUS
  isError: boolean
  isUserAlreadyExist: boolean
  submitDispatch: any
  show: boolean
}

const LoginPromptDialogBox = (props: INewLoginModalDialogProps) => {
  const {
    setIsOTPSent,
    setTimer,
    deviceInfo,
    isOTPSent,
    mobileNumber,
    timer,
    resendOTP,
    getIfUserIsNewOrOld,
    submitState,
    validatedUserAPIStatus,
    isError,
    isUserAlreadyExist,
    submitDispatch,
    show,
  } = props
  const { isOnlyMobile } = deviceInfo

  const {
    loginCustomTitle,
    loginOTPCustomTitle,
    user,
    setSidebarView,
    appVersionData,
  } = useUI()

  const newLoginFormRef: any = useRef<FormikProps<INewLoginFormProps>>(null)

  const handleLoginShow = () => {
    const tempNewUrl = window?.location?.href?.replaceAll('#loginOpen', '')
    const newUrl = tempNewUrl?.replaceAll('#loginOpen', '')
    const isLoginOpen = Router?.asPath?.includes('#loginOpen')
    Router.replace(
      {
        pathname: newUrl,
      },
      newUrl,
      {
        shallow: true,
        scroll: false,
      }
    )
    if (show && isLoginOpen) {
      Router.back()
    }
    setSidebarView('')
  }

  const handleCloseGAEvent = () => {
    recordGA4Event(window, GA_EVENT.CLOSE_BUTTON, {
      current_page: 'Login Prompt',
      previous_page: getCurrentPage(),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }

  return (
    <>
      <Transition.Root
        show={show}
        as={Fragment}
        beforeLeave={() => (document.documentElement.style.overflow = 'auto')}
      >
        <Dialog
          as="div"
          className="relative z-99"
          onClose={() => {
            handleLoginShow()
            setIsOTPSent(false)
            setTimer(0)
            // close()
          }}
        >
          <div className="fixed inset-0 left-0 bg-black/[.5]" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={`fixed ${
                  isOnlyMobile ? 'top-auto bottom-0' : 'inset-y-0'
                } right-0 flex max-w-full pointer-events-none`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-400"
                  enterFrom={`${
                    isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                  }`}
                  enterTo={`${
                    isOnlyMobile ? 'translate-y-0' : 'translate-x-0'
                  }`}
                  leave="transform transition ease-in-out duration-500 sm:duration-400"
                  leaveFrom={`${
                    isOnlyMobile ? 'translate-y-0' : 'translate-x-0'
                  }`}
                  leaveTo={`${
                    isOnlyMobile ? 'translate-y-full' : 'translate-x-full'
                  }`}
                >
                  <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                    <div
                      className={`relative z-50 flex flex-col ${
                        isOnlyMobile ? 'h-auto' : 'h-full'
                      } bg-white shadow-xl`}
                    >
                      {appVersionData?.loginbanner &&
                      appVersionData?.loginbanner?.length > 0 &&
                      !isOTPSent ? (
                        <>
                          {isOnlyMobile ? (
                            <>
                              {appVersionData?.loginbanner?.[0]
                                ?.loginbanner_mobileheroimage != '' ? (
                                <img
                                  src={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_mobileheroimage ||
                                    IMG_PLACEHOLDER
                                  }
                                  alt={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_title || ''
                                  }
                                  className={`image w-full h-40`}
                                />
                              ) : (
                                <img
                                  src={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_heroimage || IMG_PLACEHOLDER
                                  }
                                  alt={
                                    appVersionData?.loginbanner?.[0]
                                      ?.loginbanner_heroimage || ''
                                  }
                                  className={`image w-full h-40`}
                                />
                              )}
                            </>
                          ) : (
                            <img
                              src={
                                appVersionData?.loginbanner?.[0]
                                  ?.loginbanner_heroimage || IMG_PLACEHOLDER
                              }
                              alt={
                                appVersionData?.loginbanner?.[0]
                                  ?.loginbanner_heroimage || ''
                              }
                              className={`image w-full h-40`}
                            />
                          )}
                        </>
                      ) : null}
                      <div className="z-10 px-0 py-0 pb-0 sm:px-0 left-1 top-1">
                        <div className="flex justify-between px-6 py-6 pb-4 mb-0 border-b sm:px-6">
                          {!isOTPSent && (
                            <>
                              {loginCustomTitle && loginCustomTitle !== '' ? (
                                <h3
                                  className="text-xl font-bold text-black truncate dark:text-black"
                                  style={{ maxWidth: '80vw' }}
                                >
                                  {loginCustomTitle}
                                </h3>
                              ) : (
                                <div className="flex justify-center items-center gap-x-1.5">
                                  <h3 className="text-xl font-bold text-black dark:text-black">
                                    Login
                                  </h3>
                                  <span className="text-xl font-light text-black dark:text-black">
                                    or
                                  </span>
                                  <h3 className="text-xl font-bold text-black dark:text-black">
                                    Sign up
                                  </h3>
                                </div>
                              )}
                            </>
                          )}
                          {isOTPSent && (
                            <>
                              {loginOTPCustomTitle &&
                              loginOTPCustomTitle !== '' ? (
                                <>
                                  <h3 className="text-xl font-bold text-black dark:text-black flex justify-start items-center gap-x-2">
                                    <span onClick={() => setIsOTPSent(false)}>
                                      <ArrowLeft />
                                    </span>
                                    <span
                                      className="truncate"
                                      style={{ maxWidth: '70vw' }}
                                    >
                                      {loginOTPCustomTitle}
                                    </span>
                                  </h3>
                                </>
                              ) : (
                                <h3 className="text-xl font-bold text-black dark:text-black">
                                  Verify with OTP
                                </h3>
                              )}
                            </>
                          )}
                          <button
                            type="button"
                            className="text-black rounded-md outline-none hover:text-gray-500 absolute cursor-pointer"
                            style={{
                              right: '20px',
                              top: '-40px',
                            }}
                            onClick={() => {
                              handleCloseGAEvent()
                              handleLoginShow()
                              setIsOTPSent(false)
                              setTimer(0)
                            }}
                            onMouseDown={() => {
                              handleCloseGAEvent()
                              handleLoginShow()
                              setIsOTPSent(false)
                              setTimer(0)
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <Cross
                              className="relative top-0 w-7 h-7 stroke-white fill-white"
                              aria-hidden="true"
                              stroke="white"
                            />
                          </button>
                        </div>
                      </div>
                      <div className="p-6 py-2 overflow-y-auto">
                        {/* New Login Form start */}
                        <div className="flex justify-between">
                          {isOTPSent && (
                            <div className="flex items-center justify-between w-full">
                              <h3 className="my-2 text-sm text-black max-w-[70%]">
                                Please enter the OTP received on {mobileNumber}
                              </h3>
                              {timer ? (
                                <p className="text-xs text-black opacity-50">
                                  Resend in <span className="w-4">{timer}</span>
                                  s
                                </p>
                              ) : (
                                <button
                                  type="button"
                                  className="text-xs font-semibold text-orange"
                                  onClick={() => {
                                    resendOTP(newLoginFormRef?.current?.values)
                                  }}
                                >
                                  Resend OTP
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        <NewGoKwikLoginForm
                          isOTPSent={isOTPSent}
                          formId={LOGIN_FORM_ID}
                          formFields={LOGIN_NEW_MODAL_FORM_FIELDS}
                          schema={LOGIN_FORM_SCHEMA}
                          defaultValues={DEFAULT_LOGIN_VALUES}
                          onSubmit={getIfUserIsNewOrOld}
                          mobileNumber={mobileNumber}
                          ref={newLoginFormRef}
                        />

                        {!isOTPSent && (
                          <div className="px-0 py-4 pt-0 mt-2 chk-btn sm:px-0">
                            <SubmitButton
                              formId={LOGIN_FORM_ID}
                              cssClass="w-full flex items-center justify-center btn-basic-property -mr-0.5 text-white bg-black border-2 border-black rounded-sm hover:bg-gray-800 hover:text-white sm:px-6 hover:border-gray-900 disabled:bg-opacity-50 disabled:border-opacity-5"
                              submitState={submitState}
                              source={LoginPageAction.SEND_OTP}
                              disabled={
                                validatedUserAPIStatus === API_STATUS.LOADING
                              }
                            >
                              {validatedUserAPIStatus === API_STATUS.LOADING ? (
                                <LoadingDots />
                              ) : (
                                <>Request OTP</>
                              )}
                            </SubmitButton>
                          </div>
                        )}

                        {isOTPSent && (
                          <div className="px-0 py-4 pt-0 mt-2 chk-btn sm:px-0">
                            {isError ? (
                              <div className="mb-3 mt-3 m-[-24px] w-auto">
                                <div
                                  className={`bg-ribbon-red justify-center w-full px-10 py-4 text-center align-center sticky top-24 z-50`}
                                >
                                  <h4 className="text-16 font-medium text-[#fff] capitalize">
                                    {INVALID_OTP}
                                  </h4>
                                </div>
                              </div>
                            ) : null}
                            <SubmitButton
                              formId={LOGIN_FORM_ID}
                              cssClass="w-full flex items-center btn-basic-property justify-center px-4 -mr-0.5 text-white bg-black border-2 border-black rounded-sm hover:bg-gray-800 hover:text-whitesm:px-6 hover:border-gray-900 disabled:bg-opacity-50 disabled:border-opacity-5"
                              submitState={submitState}
                              source={LoginPageAction.LOGIN}
                              disabled={
                                validatedUserAPIStatus === API_STATUS.LOADING
                              }
                            >
                              {validatedUserAPIStatus === API_STATUS.LOADING ? (
                                <LoadingDots />
                              ) : (
                                <>
                                  <span className="hidden sm:inline-block">
                                    Verify OTP
                                  </span>
                                  <span className="inline-block sm:hidden">
                                    Verify & Login
                                  </span>
                                </>
                              )}
                            </SubmitButton>
                          </div>
                        )}
                        {!isOTPSent ? (
                          // Exisiting user flow
                          <>{generateLoginModalFooter(user)}</>
                        ) : (
                          <>
                            {/* GoKwik User Flow */}
                            {!isUserAlreadyExist ? (
                              <div className="text-xs dark:text-gray-500 flex justify-between items-center">
                                <span>
                                  <Link
                                    href="/terms-and-conditions"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span
                                      className="text-orange"
                                      onClick={() => policyPopupEvent(user)}
                                    >
                                      T&C
                                    </span>
                                  </Link>{' '}
                                  &{' '}
                                  <Link
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="text-orange">
                                      Privacy Policy
                                    </span>
                                  </Link>
                                </span>
                                <span className="flex justify-end items-center">
                                  <span className="mr-2">Powered by</span>{' '}
                                  <GoKwikIcon />
                                </span>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {submitState?.error && (
        <ErrorView
          error={{
            ...submitState?.error,
            ...{
              error_message: getErrorMessage(submitState?.error),
            },
          }}
          closeModal={() => resetErrorSubmitData(submitDispatch)}
        />
      )}
    </>
  )
}

export default LoginPromptDialogBox
