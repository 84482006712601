// Base Imports
import React from 'react'
import dynamic from 'next/dynamic'

// Other Imports
import { useUI } from '@components/ui'
import LoginPromptModal from '@components/account/LoginPromptModal'

const GuestLoginModal = dynamic(
  () => import('@components/account/GuestLoginModal')
)
const LoginModal = dynamic(() => import('@components/account/LoginModal'))
const LoginNewModal = dynamic(() => import('@components/account/LoginNewModal'))
const NewLoginModal = dynamic(() => import('@components/account/NewLoginModal'))

const LoginModalRouter = ({ submitState, submitDispatch, deviceInfo }: any) => {
  const {
    sidebarView,
    setSidebarView,
    setLoginCustomTitle,
    setLoginOTPCustomTitle,
  } = useUI()

  return (
    <>
      <LoginModal
        submitState={submitState}
        submitDispatch={submitDispatch}
        show={sidebarView === 'LOGIN_VIEW'}
        close={() => {
          setTimeout(() => {
            setLoginCustomTitle('')
            setLoginOTPCustomTitle('')
          }, 500)
          setSidebarView('')
        }}
        onSubmit={undefined}
        deviceInfo={deviceInfo}
      />

      <NewLoginModal
        submitState={submitState}
        submitDispatch={submitDispatch}
        show={sidebarView === 'NEW_LOGIN_VIEW'}
        close={() => {
          setTimeout(() => {
            setLoginCustomTitle('')
            setLoginOTPCustomTitle('')
          }, 500)
          setSidebarView('')
        }}
        onSubmit={undefined}
        deviceInfo={deviceInfo}
      />

      <GuestLoginModal
        submitState={submitState}
        submitDispatch={submitDispatch}
        show={sidebarView === 'GUEST_LOGIN_VIEW'}
        close={() => {
          setTimeout(() => {
            setLoginCustomTitle('')
            setLoginOTPCustomTitle('')
          }, 500)
          setSidebarView('')
        }}
        onSubmit={undefined}
      />
      <LoginNewModal
        submitState={submitState}
        submitDispatch={submitDispatch}
        show={sidebarView === 'LOGIN_NEW_VIEW'}
        close={() => {
          setLoginCustomTitle('')
          setSidebarView('')
        }}
        onSubmit={undefined}
        deviceInfo={deviceInfo}
      />
      <LoginPromptModal
        submitState={submitState}
        submitDispatch={submitDispatch}
        show={sidebarView === 'LOGIN_PROMPT_MODAL_VIEW'}
        close={() => {
          setLoginCustomTitle('')
          setSidebarView('')
        }}
        onSubmit={undefined}
        deviceInfo={deviceInfo}
      />
    </>
  )
}

export default LoginModalRouter
